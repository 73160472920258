import React from "react";
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next";
import logo from "../assets/images/original-logo2.png"


export const Footer = () => {
  const { t } = useTranslation()
  return (
    <div className="footer">
      <img src={logo} className="footer-logo" />
      <div className="footer-row">
        <Link to="/about">{t("footer.about")}</Link>|
        <Link to="/management">{t("footer.management")}</Link>|
        <Link to="/services">{t("footer.services")}</Link>|
        <Link to="/contact">{t("footer.contact")}</Link>
      </div>
      <hr />
      <div className="footer-row">
        <div className="px-2">
          {t("footer.copyright-pre")} {(new Date().getFullYear())} {t("footer.copyright-post")}
        </div>
      </div>
    </div >
  )
}