import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const ManagementPage = () => {

  const { t } = useTranslation()

  useEffect(() => {
    document.title = `${t("management.banner-text")}`
  }, [t]);

  return (
    <div className='management'>
      <div className='management-banner'>
        <h1 className='management-banner-text'>
          {t("management.banner-text")}
        </h1>
      </div>
      <br /><br /><br />
      {t('management.person', { returnObjects: true }).map((person => (
        <div>
          <div className='management-grid'>
            <h3>{person.name}</h3><br />
            <p className='test'>{person.content1}</p>
            <p className='test'>{person.content2}</p>
            <p className='test'>{person.content3}</p><br /><hr />
          </div>
        </div>
      )))}<br /><br /><br /><br />
    </div >
  )
}