import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import cn from "./assets/i18n/cn.json"
import en from "./assets/i18n/en.json"
import zh from "./assets/i18n/zh.json"

const resources = {
  cn: { translation: cn },
  en: { translation: en },
  zh: { translation: zh },
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    debug: true,
    interpolation: {
      escapeValue: false,
    }
  });

export default i18n;