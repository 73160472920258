import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './assets/styles/App.scss';

import { NavigationBar } from "./components/NavigationBar";
import { Footer } from "./components/Footer";

// import { HomePage } from './pages/HomePage';
import { AboutPage } from './pages/AboutPage';
import { ManagementPage } from './pages/ManagementPage';
import { ServicesPage } from './pages/ServicesPage';
import { ContactPage } from './pages/ContactPage';


const App = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Router>
      <NavigationBar />
      <Routes>
        <Route path="/" element={<AboutPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/management" element={<ManagementPage />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/contact" element={<ContactPage />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
