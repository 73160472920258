import React, { useEffect } from "react";
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

import logo from "../assets/images/original-logo2.png"

export const NavigationBar = () => {
  const { t, i18n } = useTranslation()
  const onChangeLanguage = (e) => {
    i18n.changeLanguage(e.target.value)
  }
  useEffect(() => {
    for (let index = 0; index < document.getElementsByClassName('lang').length; index++) {
      const element = document.getElementsByClassName('lang')[index];
      if (element.value === i18n.language) {
        element.setAttribute("selected", "true")
      }
    }
  }, [i18n.language])

  return (
    <Navbar bg="$primary" variant="dark" sticky='top' scrolling="true" dark="true" expand="lg">
      <Container>
        <Navbar.Brand href="/">
          <img src={logo} className="d-inline-block align-top nav-logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-evenly">
          <Nav className="me-auto flex-grow-1 justify-content-evenly" >
            <Nav.Link href="about" className="nav-text" >{t("nav.about")}</Nav.Link>
            <Nav.Link href="management" className="nav-text" >{t("nav.management")}</Nav.Link>
            <Nav.Link href="services" className="nav-text" >{t("nav.services")}</Nav.Link>
            <Nav.Link href="contact" className="nav-text" >{t("nav.contact")}</Nav.Link>
            <button style={{ outline: 'none' }} className="nav-text lang" value="en" onClick={onChangeLanguage}>English</button>
            <button style={{ outline: 'none' }} className="nav-text lang" value="cn" onClick={onChangeLanguage}>简体中文 </button>
            <button style={{ outline: 'none' }} className="nav-text lang" value="zh" onClick={onChangeLanguage}>繁體中文 </button>
          </Nav>
        </Navbar.Collapse>
      </Container >
    </Navbar >
  );
}