import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';
// import { Form, Button, Card } from "react-bootstrap";
import map from '../assets/images/contact-map.png'

export const ContactPage = () => {

  const { t } = useTranslation()

  useEffect(() => {
    document.title = `${t("contact.banner-text")}`
  }, [t]);

  return (
    <div className='contact'>
      <div className='contact-banner'>
        <h1 className='contact-banner-text'>
          {t("contact.banner-text")}
        </h1>
      </div>
      <div className="contact-detail">
        <div className="contact-text">
          <h4>{t("contact.contact-us")}</h4><br />
          <h1 className="contact-detail-title">{t("contact.title")}</h1><br />
          <ul>
            <li><p>{t("contact.address")}</p></li>
            <li><p>{t("contact.phone")}</p></li>
            <li><p>{t("contact.email")}</p></li>
          </ul><br />
          <img src={map} />
        </div>
      </div>
    </div >
  )
}

