import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'react-bootstrap';

export const ServicesPage = () => {

  const { t } = useTranslation()

  useEffect(() => {
    document.title = `${t("services.banner-text")}`
  }, [t]);
  return (
    <div className='services'>
      <div className='services-banner'>
        <h1 className='services-banner-text'>
          {t("services.banner-text")}
        </h1>
      </div>
      <div className='services-grid'>
        <div className='services-grid-row'>
          {t('services.grid', { returnObjects: true }).map((grid => (
            <Card className='services-grid-block'>
              {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
              <Card.Body>
                <Card.Title><h3 className='card-title'>{grid.block}</h3></Card.Title>
              </Card.Body>
            </Card>
          )))}
        </div>
      </div>
    </div >
  )
}