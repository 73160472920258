import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const AboutPage = () => {

  const { t } = useTranslation()

  useEffect(() => {
    document.title = `${t("about.banner-text")}`
  }, [t]);

  return (
    <div className='about'>
      <div className='about-banner'>
        <h1 className='about-banner-text'>
          {t("about.banner-text")}
        </h1>
      </div>
      <div className='about-intro'>
        <h2>{t("about.intro-title-1")}</h2><br />
        <p>{t("about.intro-1")}</p>
        <p>{t("about.intro-2")}</p><br /><br /><hr /><br /><br />
        <h2>{t("about.intro-title-2")}</h2><br />
        <p>{t("about.intro-3")}<a href="https://www.hkicpa.org.hk/">(HKICPA https://www.hkicpa.org.hk/)</a></p>
        <p>{t("about.intro-4")}</p><br /><br /><hr /><br />
      </div>
    </div>
  )
}
